<template>
  <div class="min-h-100 d-flex justify-content-center align-items-center">
    <div class="book text-black-50">
      <div class="page page-one d-flex flex-column">
        <div class="d-flex flex-wrap justify-content-center">
          <Item :item="{Item: recipe.outcome}" v-for="(recipe) in recipesOnPage" :key="recipe.id"
                @click="selectRecipe(recipe.id)"
                class="cursor-pointer p-2 p-md-3"/>
        </div>
        <div class="mt-auto text-center d-flex flex-row w-100 justify-content-center align-items-center">
          <button @click="previousPage" :disabled="currentPage === 1" class="btn-previous">Previous</button>
          <div class="small mx-3">{{ (currentPage) }}/{{ totalPages }}</div>
          <button @click="nextPage" :disabled="currentPage === totalPages" class="btn-next">Next</button>
        </div>
        <div class="bg"></div>
      </div>
      <div class="page page-two d-flex flex-column">
        <div v-if="!selectedRecipe">
          Select a recipe from the other page
        </div>
        <CookingRecipeRow :recipe-id="selectedRecipe" v-else/>
        <div class="bg"></div>
      </div>
    </div>
  </div>
</template>
<script>

import Item from "@/components/items/Item";
import CookingRecipeRow from "@/components/CookingRecipeRow";
import {mapGetters} from "vuex";

export default {
  name: 'About',
  components: {CookingRecipeRow, Item},
  data() {
    return {
      currentPage: 1,
      recipesPerPage: 9,
      filter: '',
      selectedRecipeId: null,
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      activeSkill: "user/activeSkill",
      currentSkill: "user/activeSkillZone"
    }),
    skills() {
      return this.$store.state.config.zones && this.$store.state.config.zones.filter(i => i.skill === 'cooking')
    },
    totalPages() {
      return Math.ceil(this.recipes.length / this.recipesPerPage);
    },
    recipes() {

      let recipes = this.skills;

      if (this.filter.length > 0) {
        recipes = recipes.filter(r => {

          return r.name.toLowerCase().includes(this.filter) || ('tags' in r && r.tags.includes(this.filter))
        })
      }

      return recipes;

    },
    recipesOnPage() {
      const currentPage = (this.currentPage - 1) * this.recipesPerPage;
      return this.recipes.slice(currentPage, currentPage + this.recipesPerPage)
    },
    selectedRecipe() {
      if (this.selectedRecipeId === null && this.currentSkill?.skill === 'cooking') {
        return this.activeSkill;
      }

      return this.selectedRecipeId
    }
  },
  methods: {
    selectRecipe(id) {
      this.selectedRecipeId = id;
    },

    nextPage() {
      if (this.currentPage < this.totalPages)
        this.currentPage++;
    },
    previousPage() {
      if (this.currentPage > 1)
        this.currentPage--;
    },
  }
}
</script>
