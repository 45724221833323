<template>
  <div class="d-flex flex-column recipe-column flex-grow-1"
       :class="{'disabled': !hasIngredients}"
       v-if="recipe">
    <div class="d-flex align-items-center">
      <h4>{{ recipe.name }}</h4>
      <div class="ms-auto">

        <v-tooltip class=" d-flex flex-row align-items-center">

          <img src="/img/sprites/flame.png"/>
          <span :class="{'text-danger': !hasEnoughHeat}">{{ recipe.heat }}</span>

          <template #popper>
            Gain heat by consuming coal and sticks
          </template>
        </v-tooltip>
      </div>
    </div>
    <div class="messages">
      <transition-group name="messageList">
        <div class="message" v-for="m in messages" :key="m.id">
          <div class="d-inline-flex px-4 flex-row align-items-center justify-content-center">
            <template v-if="m.items">
              <Item
                  v-for="(i, index) in m.items"
                  :key="index"
                  :item="{Item: i}"
                  :size="32"/>
            </template>
            {{ m.message }}
          </div>
        </div>
      </transition-group>
    </div>
    <h5>Ingredients for <strong>{{ recipe.outcome.name }}</strong></h5>
    <div class="ingredients d-flex flex-row">
      <Item :item="{Item: ing.item}" v-for="(ing, i) in recipe.ingredients" :key="i" class="me-2" :size="36"/>
    </div>
    <div class="d-flex align-items-center mt-auto mb-2 w-100">
      <button @click="startSkill" v-if="!started && !waitingForStatus" :disabled="!hasIngredients"
              class="btn btn-primary w-100">Cook
      </button>
      <button @click="stopSkill" v-if="started || waitingForStatus" class="btn btn-primary w-100">Stop</button>
    </div>
    <SocketProgressBar :skill="recipe" class="mb-5"/>
  </div>
</template>
<style lang="scss" scoped>

.message .item {
  background: none !important;
}
</style>
<script>
import SocketProgressBar from "./SocketProgressBar";
import store from '../store'
import Item from "@/components/items/Item";
import {mapGetters} from "vuex";

export default {
  name: 'CookingRecipeRow',
  components: {Item, SocketProgressBar},
  data() {
    return {
      store: store.state,
      started: false,
      messages: [],
    }
  },
  props: {
    recipeId: {
      type: Number,
      required: true,
    }
  },
  methods: {
    startSkill() {
      this.$socket.emit('auth:start skill', this.recipe.id)
    },
    stopSkill() {
      this.$socket.emit('auth:stop skill')
    }
  },
  computed: {
    ...mapGetters({
      inventory: "user/inventory",
      heat: "user/heat",
    }),
    recipe() {
      return this.$store.state.config.zones && this.$store.state.config.zones.find(i => i.skill === 'cooking' && i.id === this.recipeId)
    },
    hasIngredients() {
      let hasEnough = true;

      for (const i in this.recipe.ingredients) {
        const reqIngredient = this.recipe.ingredients[i];

        const inventoryItem = this.inventory.find(s => s.item_id === reqIngredient.item.id && s.amount >= (reqIngredient.quantity || 1));

        if (!inventoryItem)
          hasEnough = false;
      }

      if (!this.hasEnoughHeat)
        return false;

      return hasEnough;
    },
    waitingForStatus() {
      return this.store.user.character.current_skill === this.recipe.id && !this.started;
    },
    hasEnoughHeat() {
      return this.heat >= this.recipe.heat
    }
  },
  sockets: {
    'GAIN_XP': function (skill) {
      if (skill.skill === this.recipe.id && this.messages.length < 5) {
        this.messages.push({
          id: this.messages.length + Math.floor(Math.random() * 100),
          message: `+${skill.xp}xp`,
          items: skill.items
        });

        setTimeout(() => {
          this.messages.splice(0, 1);
        }, 3000);
      }
    },
    'STOP_SKILL': function () {
      this.started = false;
    },
    'START_SKILL': function (skill) {
      if (skill.id === this.recipe.id) {
        this.timer = skill.timer;
        this.started = true;
      }
    },
  }
}
</script>
